/* Root Elements classes */
body {
  background-color: $light-color;
  color: $dark-color;
  font-family: 'Montserrat', sans-serif;
  
  background-image: url("../assets/images/background.png");
  background-repeat: repeat;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Suez One', serif;
  font-weight: lighter;
}

a {
  &:link {
    text-decoration: none;  
  }
  text-decoration: none;
  color: $dark-color;
}
a:hover {
  color: $dark-color * 1.2;
}

.no-focus-outline a:focus,
.no-focus-outline button:focus,
.no-focus-outline input:focus {
  outline: none;
}