.o-header {
  position: fixed;
  width: 100%;
  @include scale-min-max(height, 65, 75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1; // this was needed after Bootstrap
  pointer-events: none;

  .o-header-nav {
      @include media-breakpoint-only(xs) {
          flex-basis: 0;
      }
      display: flex;
      align-items: center;
      a {
        pointer-events: all;
      }
  }
  
  .o-header-link[href="#cart"] {
    @include scale-min-max(width, 40, 45);
    @include scale-min-max(height, 40, 45);
  }
}

.o-header-link {
  h2 {
    @include scale-min-max(font-size, 18, 26);
  }
}

.o-header-logo {
  flex-grow: 1.4;
  @include media-breakpoint-only(xs) {
    order: 2;
    justify-content: center;
  }
  @include media-breakpoint-up(sm) {
    margin-left: 20px;
  }
}

.o-header-menu {
  .o-header-link {
    @include scale-min-max(margin-left, 20, 50);
  }

  @include media-breakpoint-only(xs) {
    order: 1;
    flex-grow: 1;

    .o-header-link {
        margin-left: 15px;
        &[href="#story"], &[href="/#story"] {
          display: none;
        }
    }
  }
}

.o-header-cart {
  @include media-breakpoint-up(sm) {
    padding-left: 40px;
    padding-right: 10px;
  }

  @include media-breakpoint-only(xs) {
    order: 3;
    flex-grow: 1;
    justify-content: flex-end;
  }
}

.o-header-size {
  width: 100%;
  @include scale-min-max(height, 65, 75);
}