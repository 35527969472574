.s-background-product-details-male-black {
  background-image: url("../assets/images/product-details-male-black.png");
}
.s-background-product-details-male-blue {
  background-image: url("../assets/images/product-details-male-blue.png");
}
.s-background-product-details-male-green {
  background-image: url("../assets/images/product-details-male-green.png");
}
.s-background-product-details-male-red {
  background-image: url("../assets/images/product-details-male-red.png");
}
.s-background-product-details-male-yellow {
  background-image: url("../assets/images/product-details-male-yellow.png");
}

.s-background-product-details-female-black {
  background-image: url("../assets/images/product-details-female-black.png");
}
.s-background-product-details-female-blue {
  background-image: url("../assets/images/product-details-female-blue.png");
}
.s-background-product-details-female-green {
  background-image: url("../assets/images/product-details-female-green.png");
}
.s-background-product-details-female-red {
  background-image: url("../assets/images/product-details-female-red.png");
}
.s-background-product-details-female-yellow {
  background-image: url("../assets/images/product-details-female-yellow.png");
}

.s-background-product-folded {
  background-image: url("../assets/images/product-folded.png");
}
