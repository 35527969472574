.s-icon-down::after {
  @include media-breakpoint-down(xs) {
    content: '';
    background: url('../assets/icons/down-arrow.svg');
    background-size: 100% 100%;
    width: 18px;
    height: 10px;
    display: inline-block;
    margin-left: 2px;
  }
}

.s-icon-cart-empty {
  background: url('../assets/icons/empty-cart.png');
  background-size: 100% 100%;
}

.s-icon-insta {
  background: url('../assets/icons/social/insta.svg');
  background-size: 100% 100%;
}

.s-icon-fb {
  background: url('../assets/icons/social/fb.svg');
  background-size: 100% 100%;
}

.s-icon-twitter {
  background: url('../assets/icons/social/twitter.svg');
  background-size: 100% 100%;
}