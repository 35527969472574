// wrapper-content contains common styles for the outermost container of all wrappers.
// For example, this would be a good place to add margins around the entire content.
.o-wrapper-container {
  @extend .container-fluid;
  @include media-breakpoint-up(lg) {
    //padding: 0 50px;
  }
  @include media-breakpoint-up(xl) {
    //padding: 0 100px;
  }
}

.o-wrapper-hero {
  @include media-breakpoint-up(lg) {
    position: relative;
    width: 100%;
    height: 80vh;
    min-height: 800px;

    display: flex;
  }
  
  @include media-breakpoint-down(md) {
    margin-bottom: 50px;
  }
}

.o-wrapper-hero-image {
  @include media-breakpoint-up(lg) {
    position: absolute;
    right: 0;
    width: auto;
    max-height: 100%;
  }
  
  @include media-breakpoint-down(md) {
    height: auto;
    width: 100%;
    object-fit: contain;
  }
}

.o-wrapper-hero-cta {
  @extend .col-12;
  @extend .col-lg-6;
  justify-content: center;
  text-shadow: 0 0 0.5em $light-color;
}

.o-wrapper-row {
  @extend .row;
  align-items: center;
  margin-bottom: 50px;
}

.o-wrapper-col {
  @extend .col;
}

.o-wrapper-col-fullwidth {
  @extend .col-12;
}

.o-wrapper-col-mobilefullwidth {
  @extend .col-12;
  @extend .col-lg-6;
}

.o-wrapper-col-mobile-hide {
  @extend .col;
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.o-content-cta-text {
  display: flex;
  flex-direction: column;
  text-align: center;
  h2 {
    @include scale-min-max(font-size, 36, 55);
    padding-bottom: 35px;
  }

  p {
      @include scale-min-max(font-size, 20, 28);
      line-height: 150%;
      padding-bottom: 50px;
  }

  a {
      @include scale-min-max(font-size, 24, 36);
      font-weight: bold;
  }

  @include media-breakpoint-down(md) {
    padding: 0 50px;
  }
}

.o-content-background-contain {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.o-content-image-collage {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 30px;

  a {
    flex-basis: 20%;

    @include media-breakpoint-down(md) {
      flex-basis: 30%;
    }

    img {
      width: 100%;
    }
  }
}

.o-content-footernav {
  @include media-breakpoint-down(md) {
    margin: 0 100px;
  }
  @include media-breakpoint-down(sm) {
    margin: 0 10px;
  }

  .o-content-footernav-site {
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    height: 250px;
    div {
      width: 50%;
    }
    @include scale-min-max(font-size, 24, 32);
    margin-bottom: 50px;

    @include media-breakpoint-down(md) {
      text-align: center;
    }
  }

  .o-content-footernav-social {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    a {
      @include scale-min-max(width, 60, 75);
      @include scale-min-max(height, 60, 75);
    }
  }
}

.o-display-tablet-up {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.o-display-mobile-down {
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.o-story-section-wrapper {
  @extend .o-wrapper-row;
  margin-bottom: 0;
}

.o-story-hero-wrapper {
  background: url("../assets/images/mission.jpg");
  background-size: cover;
  background-position: center;

  width: 100%;
  height: 0;
  
  @media only screen and (orientation: landscape) {
    padding-bottom: 50%;
  }
  @media only screen and (orientation: portrait) {
    padding-bottom: 133%;
  }

  position: relative;
  div {
    width: 100%;
    position: absolute;
    color: #f5f5f5;
    text-align: center;
    bottom: 60px;
    left: 0;
  }
  h2 {
    @include scale-min-max(font-size, 36, 55);
    padding-bottom: 35px;
  }
  a {
    color: #f5f5f5;
    display: block;

    &:hover {
      color: #eeeeee;
    }

    @include scale-min-max(font-size, 24, 36);
    font-weight: bold;
  }
}

.o-shop-wrapper {
  @extend .o-wrapper-row;
}

.o-product-details-wrapper {
  @include media-breakpoint-down(lg) {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  width: fit-content;
}

.o-product-details-text {
  @include media-breakpoint-down(lg) {
    text-align: center;
  }
  @include scale-min-max(margin-bottom, 30, 50);
  h2 {
    @include scale-min-max(font-size, 32, 36);
    margin-bottom: 20px;
  }
  p {
    @include scale-min-max(font-size, 20, 24);
    line-height: 1.5em;
    margin-bottom: 10px;
  }
}

.o-product-color-picker {
  @include media-breakpoint-down(lg) {
    text-align: center;
    order: -1;
  }
  margin-bottom: 20px;
  button {
    margin-right: 10px;
    &:last-of-type() {
      margin-right: 0;
    }
  }
}

.o-product-gender-picker {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px;
  @include media-breakpoint-down(lg) {
    height: 150px;
    align-items: flex-end;
  }
  background-color: #333333;
  .o-button-toggle-text {
    margin: 0 5px;
    font-size: 1.5em;
    flex-basis: 50%;
    max-width: 200px;
    flex-grow: 0;
    height: 50px;
    @include media-breakpoint-down(lg) {
      margin-bottom: 10px;
    }
  }
}

.o-product-size-picker {
  @include media-breakpoint-down(lg) {
    text-align: center;
  }
  margin-bottom: 20px;
  span {
    display: inline-block;
    margin-bottom: 10px;
  }
  .o-button-toggle {
    margin-right: 5px;
    @include scale-min-max(width, 30, 50);
    @include scale-min-max(height, 30, 50);
    @include scale-min-max(border-radius, 12, 15);
    @include scale-min-max(font-size, 18, 24);
    &:last-of-type() {
      margin-right: 0;
    }
  }
  .o-button-text {
    padding: 0;
    font-size: 1.05em;
    font-weight: bold;
  }
}

.o-product-buy-wrapper {
  @include media-breakpoint-down(lg) {
    text-align: center;
    width: 90%;
    margin: 0 auto;
  }
  button {
    width: 100%;
    margin-bottom: 20px;
  }
}

@mixin button-circle {
  width: 50px;
  height: 50px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border-width: 3px;
  border-color: #bbbbbb;
  border-style: solid;
}

.o-button-circle {
  @include button-circle;
  
  &--blue {
    background: #add8e6;
    border-color: #add8e6 * 0.9;
  }
  &--black {
    background: #545454;
    border-color: #545454 * 0.9;
  }
  &--red {
    background: #ff5757;
    border-color: #ff5757 * 0.9;
  }
  &--yellow {
    background: #ffbd59;
    border-color: #ffbd59 * 0.9;
  }
  &--green {
    background: #bab86c;
    border-color: #bab86c * 0.9;
  }

  &--selected {
    border-color: #ffffff;
  }
}

.o-button-primary {
  background-color: $dark-color;
  color: $light-color;
  font-size: 24px;
  border-radius: 15px;
  border-width: 0;
  height: 54px;
  font-family: 'Montserrat', sans-serif;

  &:hover {
    background-color: $dark-color * 1.2;
  }
}

@mixin button-toggle {
  width: 50px;
  height: 50px;
  border-radius: 15px;
  border-width: 3px;
  border-style: solid;
  box-sizing: content-box;
  font-size: 24px;
  font-family: 'Montserrat', sans-serif;

  background-color: $light-color;
  border-color: $dark-color * 0.9;
  color: $dark-color;
  
  &:hover {
    border-color: $dark-color * 1.2;
    color: $dark-color * 1.2;
  }
}
.o-button-toggle {
  @include button-toggle;

  &--selected {
    background-color: $dark-color;
    border-color: #ffffff;
    color: $light-color;
    &:hover {
      border-color: #ffffff;
      color: $light-color;
    }
  }
}

@mixin button-toggle-text {
  padding: 0;
  background: none;
  border: none;
  color: #f5f5f5;
  &:hover {
    opacity: 0.8;
  }
}
.o-button-toggle-text {
  @include button-toggle-text;
  &--selected {
    border-bottom: 3px solid #f5f5f5;
    padding-bottom: 3px;
    font-weight: bold;
  }
}

@mixin button-text {
  background: none;
  border: none;
  color: $dark-color;
  &:hover {
    opacity: 0.8;
  }
}

.o-button-text {
  @include button-text;
}

.o-product-hero-wrapper {
  background: url("../assets/images/product-hero-male-black.jpg");
  background-size: cover;
  background-position: center;

  width: 100%;
  height: 0;
  @media only screen and (orientation: landscape) {
    padding-bottom: 56.25%;  
  }
  @media only screen and (orientation: portrait) {
    padding-bottom: 177%;
  }
}

.modal-open {
  overflow: hidden;
}

.o-modal-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0,0,0,0.5);
}

.o-sizing-chart-modal {
  max-width: 300px;
  margin: 10vh auto;
  background-color: darkgray;
  color: white;
  border-radius: 8px;
  text-align: center;

  font-size: 0.9rem;

  table {
    width: 280px;
    margin: 0 auto;
    margin-bottom: 20px;
    border-collapse: separate;
    border-spacing: 0 10px;
  }

  th, td:first-child() {
    font-weight: bold;
    font-size: 1.1em;
  }

  p {
    font-size: 0.7em;
    padding-bottom: 20px;
  }

  h2 {
    padding-top: 20px;
    margin-bottom: 20px;
  }
}

.o-subscribe-modal {
  max-width: 300px;
  margin: 10vh auto;
  background-color: darkgray;
  color: white;
  border-radius: 8px;
  padding: 0 20px;
  text-align: center;

  .o-subscribe-modal-title {
    padding-top: 20px;
    margin-bottom: 20px;
  }

  .o-subscribe-modal-message {
    padding-bottom: 20px;
    text-align: justify;
  }

  .o-subscribe-modal-status {
    padding: 20px 0;
    text-align: left;
  }

  input {
    border: 4px solid #555555;
    border-radius: 15px;
    background-color: darkgray;
    width: 100%;
    height: 45px;
    margin-bottom: 10px;

    &::-webkit-input-placeholder {
      color: gray;
    }
    &::-moz-placeholder {
      color: gray;
    }
    &:-ms-input-placeholder {
      color: gray;
    }
    &:-moz-placeholder {
      color: gray;
    }
  }

  button {
    height: 45px;
    width: 100%;
    background-color: #555555;
    color: white;
    font-size: 1em;
    &:hover {
      background-color: #555555 * 1.2;
    }
  }
}
.o-notice-wrapper {
  font-size: 1.5em;
  h1 {
    text-align: center;
    margin-top: 40px;
  }
  p {
    text-align: justify;
    margin-top: 40px;
  }
}
